import { Avatar, Box, Button, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Drawer, Fade, FormControlLabel, Icon, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, ListSubheader, makeStyles, Slide, Switch, TextField, Typography } from '@material-ui/core'
import React, { FormEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Route, useHistory, useLocation, useParams } from 'react-router-dom'
import { BlockDefinition, BlockProps } from '.'
import RichTextEditor from '../../components/RichTextEditor'
import { useDropzone } from 'react-dropzone'
import { storage, siteName } from '../../'
import firebase from 'firebase'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import { TransitionProps } from '@material-ui/core/transitions/transition'
import arrayMove from 'array-move'
import { ConfirmDeleteContext } from '../../components/ConfirmDeleteDialog'
import { useCollection, useDoc, useFirestore } from '../../hooks/firebase'
import Masonry from 'react-masonry-component'
import Firebase from 'firebase/app'
import { grey } from '@material-ui/core/colors'
const { readAndCompressImage } = require('browser-image-resizer')

const thumbConfig = {
  quality: 1,
  maxWidth: 608
}

type Project = { title: string, isProject: boolean, description?: string, url?: string, thumbUrl?: string, order?: number, parent?: string }

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    position: 'relative'
  },
  dropContainer: {
    marginTop: theme.spacing(1),
    border: `2px dashed ${theme.palette.grey[400]}`,
    borderRadius: 4,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
    outline: 'none'
  },
  dropContainerActive: {
    marginTop: theme.spacing(1),
    border: `2px dashed ${theme.palette.primary.main}`,
    borderRadius: 4,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
    outline: 'none'
  }
}))

const useGridItemStyles = makeStyles({
  overlay: {
    opacity: 0,
    transition: 'opacity 0.5s ease-in-out',
    '&:hover': {
      opacity: 1,
      transition: 'opacity 0.5s ease-in-out'
    }
  },
  project: {
    '&::before': {
      background: grey[50],
      borderColor: 'transparent ' + grey[200] + ' transparent transparent',
      borderStyle: 'solid',
      borderWidth: '40px 40px 0 0',
      content: `''`,
      position: 'absolute',
      zIndex: 20
    },
  }
})

function GridItem({ drawing, isFirst, numOfImages }: { drawing: Project & { id: string }, isFirst: boolean, numOfImages?: number }) {
  const { overlay, project } = useGridItemStyles()
  const { page } = useParams<{ page?: string }>()
  const history = useHistory()
  return <Box className={`grid-item ${isFirst && 'first'}`} padding={0.5} paddingBottom={0}>
    <Box position="relative" width="100%" style={{ cursor: 'pointer' }} className={drawing.isProject ? project : undefined} onClick={() => history.push(`/${page}/${drawing.id}`)}>
      <img src={drawing.thumbUrl} alt="" style={{ width: '100%' }} />
      <Box width="100%" height="100%" position="absolute" top={0} left={0} className={overlay}>
        <Box width="100%" height="100%" style={{ background: '#fff', opacity: 0.2 }} />
        <Box position="absolute" bottom={5} left={0} alignItems="center" width="100%" padding={2} display="flex" style={{ opacity: 0.7, background: '#000', color: '#fff' }}>
          <Typography style={{ flex: 1 }}>{drawing.title}{numOfImages ? <span style={{ whiteSpace: 'nowrap' }}>{` (${numOfImages} ${numOfImages === 1 ? 'image' : 'images'})`}</span> : null}</Typography>
          {drawing.isProject && <Button style={{ color: '#fff', whiteSpace: 'nowrap' }} size="small">More info</Button>}
        </Box>
      </Box>
    </Box>
  </Box>
}


const useDialogStyles = makeStyles({
  paper: {
    boxShadow: 'none',
    background: 'none',
    color: '#fff',
    alignItems: 'center',
    maxWidth: 'none',
    width: '100%',
    borderRadius: 0
  },
  root: {
    '& > div:first-child': {
      backgroundColor: 'rgba(0, 0, 0, 0.8)'
    }
  }
})

const FadeTransition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Fade ref={ref} {...props} timeout={800} />
})

function DrawingDialog({ drawing, drawings, open, onNavigate, onClose }: { drawing: Project & { id: string }, drawings?: Array<Project & { id: string }>, open: boolean, onNavigate: (drawingId: string) => void, onClose: () => void }) {
  const [staleDrawing, setStaleDrawing] = useState(drawing)
  const siblings = useMemo(() => staleDrawing.parent ? drawings?.filter(d => d.parent === staleDrawing.parent) : [], [drawings, staleDrawing])
  const currentIndex = useMemo(() => siblings?.findIndex(d => d.id === staleDrawing.id), [siblings, staleDrawing])
  useEffect(() => {
    if (!drawing.isProject) {
      setStaleDrawing(drawing)
    }
  }, [drawing])
  const classes = useDialogStyles()
  const handleNext = useCallback(() => {
    typeof currentIndex !== 'undefined' && siblings && (currentIndex + 1 < siblings.length) && onNavigate(siblings[currentIndex + 1].id)
  }, [currentIndex, siblings, onNavigate])
  const handlePrevious = useCallback(() => {
    typeof currentIndex !== 'undefined' && siblings && currentIndex > 0 && onNavigate(siblings[currentIndex - 1].id)
  }, [currentIndex, siblings, onNavigate])
  const keyDownListener = useCallback((e: KeyboardEvent) => {
    e.code === 'ArrowRight' && handleNext()
    e.code === 'ArrowLeft' && handlePrevious()
  }, [handleNext, handlePrevious])
  useEffect(() => {
    window.addEventListener('keydown', keyDownListener)
    return () => {
      window.removeEventListener('keydown', keyDownListener)
    }
  }, [keyDownListener])
  return <Dialog open={open} onClose={onClose} TransitionComponent={FadeTransition} classes={classes}>
    <Box flex='0 1 auto' overflow="hidden" textAlign="center" onClick={onClose} marginBottom={2}>
      <img src={staleDrawing.url} alt={staleDrawing.title} style={{ maxWidth: '100%', maxHeight: '100%' }} />
    </Box>
    <Typography>{staleDrawing.title}</Typography>
    {typeof currentIndex !== 'undefined' && currentIndex > 0 && <IconButton onClick={() => handlePrevious()} color="inherit" style={{ position: 'absolute', left: 16, top: '50%' }}><Icon>chevron_left</Icon></IconButton>}
    {typeof currentIndex !== 'undefined' && (currentIndex + 1) < (siblings ? siblings.length : 0) && <IconButton onClick={() => handleNext()} color="inherit" style={{ position: 'absolute', right: 16, top: '50%' }}><Icon>chevron_right</Icon></IconButton>}
    <IconButton onClick={onClose} color="inherit" style={{ position: 'absolute', right: 16, top: 0 }}><Icon>close</Icon></IconButton>
  </Dialog>
}

function MasonryMain({ props }: { props?: BlockProps }) {
  const history = useHistory()
  const firestore = useFirestore()
  const [project, setProject] = useState<(Project & { id: string }) | undefined>()
  const [selectedDrawing, setSelectedDrawing] = useState<(Project & { id: string }) | undefined>()
  const { drawingId, page } = useParams<{ drawingId?: string, page?: string }>()
  const queryCallback = useCallback((collection: Firebase.firestore.CollectionReference) => collection.orderBy('order'), [])
  const [drawings] = useCollection<Project>(`site-data/${siteName}/drawings`, queryCallback)
  const projectId = useMemo(() => selectedDrawing?.isProject ? selectedDrawing.id : selectedDrawing?.parent, [selectedDrawing])
  const filtered = useMemo(() => projectId ? drawings.filter(d => d.parent === projectId) : drawings.filter(d => !d.parent), [projectId, drawings])
  useEffect(() => {
    if (drawingId) {
      const docRef = firestore.collection(`site-data/${siteName}/drawings`).doc(drawingId)
      docRef?.get().then(doc => {
        setSelectedDrawing({ id: doc.id, ...doc.data() as Project })
      })
    } else {
      setSelectedDrawing(undefined)
    }
  }, [drawingId, firestore])
  useEffect(() => {
    if (projectId) {
      const docRef = firestore.collection(`site-data/${siteName}/drawings`).doc(projectId || '')
      docRef?.get().then(doc => {
        setProject({ id: doc.id, ...doc.data() as Project })
      })
    } else {
      setProject(undefined)
    }
  }, [projectId, firestore])
  return <React.Fragment><Masonry className="masonry" options={{ stamp: '.stamp', itemSelector: '.grid-item', columnWidth: '.grid-sizer', }}>
    <Box className="grid-sizer" />
    {(selectedDrawing?.isProject || selectedDrawing?.parent) ? <Box className="stamp" top={0} left={0} position="absolute" padding={0.5}><div style={{ background: '#eee', padding: 16 }}>
      <Button color="secondary" size="small" variant="outlined" onClick={() => history.push(`/${page}`)} style={{ marginBottom: 16, whiteSpace: 'nowrap' }}>{`Back to overview`}</Button>
      <Typography variant="h5">{project?.title}</Typography>
      <Box dangerouslySetInnerHTML={{ __html: project?.description || '' }} />
    </div></Box> :
      <Box className="stamp" top={0} right={0} position="absolute" padding={0.5}><div style={{ background: '#eee', padding: 16, height: 272, overflow: 'auto' }}>
        <Box dangerouslySetInnerHTML={{ __html: props?.content || '' }} />
      </div></Box>}
    {filtered.map((drawing, k) => <GridItem key={k} drawing={drawing} isFirst={!selectedDrawing && k === 0} numOfImages={drawing.isProject ? drawings.filter(d => d.parent === drawing.id).length : undefined} />)}
  </Masonry>
    {selectedDrawing && <DrawingDialog
      open={selectedDrawing && !selectedDrawing.isProject}
      drawing={selectedDrawing}
      drawings={drawings}
      onClose={() => history.push(`/${page}${project ? '/' + project.id : ''}`)}
      onNavigate={drawingId => history.push(`/${page}/${drawingId}`)}
    />}
  </React.Fragment>
}

function Main({ props }: { props?: BlockProps }) {
  return <Route path="/:page/:drawingId?"><MasonryMain props={props} /></Route>
}

function RtfInputComponent({ value, onChange }: React.PropsWithChildren<{ value?: string, onChange?: (e: FormEvent<HTMLTextAreaElement | HTMLInputElement>) => void }>) {
  const handleChange = useCallback((e: { target: any }, value: string) => {
    onChange && onChange({ ...e, target: { ...e.target, value } } as FormEvent<HTMLTextAreaElement | HTMLInputElement>)
  }, [onChange])
  return <RichTextEditor value={value || ''} onChange={handleChange} />
}

function RenderPreviewImg({ file, progress, onRemove }: React.PropsWithChildren<{ file: File, progress: number, onRemove: (e: any) => void }>) {
  const [img, setImg] = useState('')
  const reader = new FileReader()
  reader.onloadend = function () {
    setImg(reader.result as string || '')
  }
  reader.readAsDataURL(file)
  return <Box padding={1} position="relative">
    <img src={img} alt="" height={140} style={{ borderRadius: 4 }} />
    {progress > 0 && <Box position="absolute" display="flex" alignItems="center" justifyContent="center" bottom={0} left={0} width="100%" height={`${progress * 100}%`} style={{ background: '#fff', color: '#000', opacity: 0.7 }}>{Math.round(progress * 100)}%</Box>}
    <IconButton size="small" style={{ position: 'absolute', top: 0, right: 0, background: 'rgba(0, 0, 0, 0.54)', color: '#fff' }} onClick={onRemove}>
      <Icon>clear</Icon>
    </IconButton>
  </Box>
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

function AddDrawingDialog({ open, initialState, onClose, onSave }: React.PropsWithChildren<{ open: boolean, initialState: Project & { id?: string }, onClose: () => void, onSave: (project: Project & { id?: string, files?: Array<{ title: string, url: string, thumbUrl: string }> }) => Promise<void> }>) {
  const [state, setState] = useState(initialState)
  const [files, setFiles] = useState<File[]>([])
  const [saving, setSaving] = useState(false)
  const [fileProgress, setFileProgress] = useState<number[]>([])
  const { dropContainer, dropContainerActive } = useStyles()
  const disabled = useMemo(() => state.title === '' || (!initialState.id && files.length === 0), [state, initialState, files])
  useEffect(() => {
    setFileProgress(Array(files.length).fill(0))
  }, [files])
  useEffect(() => {
    setState(initialState)
    setSaving(false)
    setFiles([])
  }, [open, initialState])
  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFiles(acceptedFiles)
  }, [])
  const handleRemove = useCallback((e: any, index: number) => {
    e.preventDefault()
    e.stopPropagation()
    setFiles(f => {
      f.splice(index, 1)
      return [...f]
    })
  }, [])
  const handleSave = useCallback(async () => {
    if (!initialState.id) {
      const storageRef = storage.ref(siteName)
      const promisses = files.map(async (f, k) => {
        const ref = storageRef.child(f.name)
        const task = ref.put(f)
        const thumbRef = storageRef.child(`thumb_${f.name}`)
        const thumb = await readAndCompressImage(files[0], thumbConfig)
        const thumbTask = thumbRef.put(thumb)
        task.on(firebase.storage.TaskEvent.STATE_CHANGED, snapshot => {
          const progress = snapshot.bytesTransferred / snapshot.totalBytes
          setFileProgress(p => {
            p[k] = progress
            return [...p]
          })
        })
        await task
        await thumbTask
        return { title: task.snapshot.ref.name, url: await task.snapshot.ref.getDownloadURL(), thumbUrl: await thumbTask.snapshot.ref.getDownloadURL() }
      })
      const uploadedFiles = await Promise.all(promisses)
      setSaving(true)
      await onSave({ ...state, files: uploadedFiles })
    } else {
      setSaving(true)
      await onSave({ id: initialState.id, ...state })
    }
  }, [state, files, onSave, initialState])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'image/jpeg, image/png', multiple: state.isProject })
  return <Dialog open={open} onClose={onClose} TransitionComponent={Transition}>
    <DialogTitle>Tekening{!initialState.id && !initialState.parent && ' of project'} {initialState.id ? 'bewerken' : 'toevoegen'}</DialogTitle>
    <DialogContent>
      <TextField margin="normal" variant="filled" label="Titel" name="title" required={true} fullWidth={true} value={state.title} onChange={e => setState(s => ({ ...s, title: e.target.value }))} />
      {!initialState.id && !initialState.parent && <FormControlLabel
        checked={state.isProject}
        onChange={() => setState(s => ({ ...s, isProject: !s.isProject }))}
        control={<Switch name="isProject" />}
        label="Dit is een project"
      />}
      <Collapse in={state.isProject}>
        <TextField
          margin="normal"
          multiline={true}
          variant="filled"
          label="Beschrijving"
          name="description"
          fullWidth={true}
          InputLabelProps={{ shrink: true }}
          InputProps={{ inputComponent: RtfInputComponent }}
          value={state.description}
          onChange={e => setState(s => ({ ...s, description: e.target.value }))}
        />
      </Collapse>
      <Box {...getRootProps()} className={isDragActive ? dropContainerActive : dropContainer}>
        <input {...getInputProps()} />
        {files.length > 0 ? files.map((f, k) => <RenderPreviewImg file={f} progress={fileProgress[k]} key={k} onRemove={e => handleRemove(e, k)} />)
          : <Typography variant="body2">Sleep afbeeldingen hierheen, of klik om afbeeldingen te selecteren</Typography>}
      </Box>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => onClose()}>Annuleren</Button>
      <Button color="primary" onClick={handleSave} disabled={disabled}>Opslaan</Button>
    </DialogActions>
    {saving && <Box position="absolute" top={0} left={0} height="100%" width="100%" display="flex" alignItems="center" justifyContent="center" style={{ background: 'rgba(250, 250, 250, 0.87)' }}><CircularProgress size={120} color="primary" /></Box>}
  </Dialog>
}

const DragHandle = SortableHandle(() => <Box marginRight={2} overflow="hidden" width={16} height={18} style={{ cursor: 'move' }}><Icon color="disabled">dialpad</Icon></Box>)

const useListItemStyles = makeStyles({
  container: {
    listStyleType: 'none !important'
  }
})

const SortableGridTile = SortableElement(({ drawing, selectedDrawing, onDelete, onEdit, onToggleCover }: { drawing: Project, selectedDrawing?: Project, onDelete: () => void, onEdit: () => void, onToggleCover: (thumbUrl: string) => void }) => {
  const classes = useListItemStyles()
  return <Box>
    <ListItem style={{ paddingRight: 108 }} classes={classes}>
      <DragHandle />
      <ListItemAvatar>
        <Avatar src={drawing.thumbUrl} variant="rounded" />
      </ListItemAvatar>
      <ListItemText primary={drawing.title} secondary={drawing.isProject ? 'Project' : undefined} />
      <ListItemSecondaryAction>
        {drawing.parent ? <IconButton size="small" onClick={() => onToggleCover(drawing.thumbUrl || '')}><Icon color={selectedDrawing?.thumbUrl === drawing.thumbUrl ? 'primary' : 'default'}>check_circle</Icon></IconButton> : null}
        <IconButton size="small" onClick={() => onEdit()}><Icon color="primary">create</Icon></IconButton>
        <IconButton size="small" onClick={() => onDelete()}><Icon color="error">delete</Icon></IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  </Box>
})

const SortableGrid = SortableContainer(({ subheader, selectedDrawing, drawings, onDelete, onToggleCover }: { subheader: string, selectedDrawing?: Project, drawings: Array<Project & { id: string }>, onDelete: (id: string) => void, onToggleCover: (thumbUrl: string) => void }) => {
  const history = useHistory()
  const { page, id } = useParams<{ page: string, id?: string }>()
  return <List subheader={<ListSubheader>{subheader} <Button size="small" variant="contained" color="primary" style={{ marginLeft: 16, maxHeight: 500, overflow: 'auto' }} onClick={() => history.push(`/edit/page/${page}/add${Boolean(id) ? `/${id}` : ''}`)}>Toevoegen</Button></ListSubheader>}>
    {drawings.map((drawing, k) => {
      return <SortableGridTile
        key={`drawing-${drawing.id}`}
        index={k} drawing={drawing}
        selectedDrawing={selectedDrawing}
        onDelete={() => onDelete(drawing.id)}
        onEdit={() => history.push(`/edit/page/${page}/edit/${drawing.id}`)}
        onToggleCover={onToggleCover}
      />
    })}
  </List>
})

let timeout: NodeJS.Timeout

function Edit({ props, onChange }: { props?: BlockProps, onChange: (props?: BlockProps) => void }) {
  const { drawerPaper } = useStyles()
  const history = useHistory()
  const { id, page } = useParams<{ page: string, id?: string }>()
  const location = useLocation()
  const confirmDelete = useContext(ConfirmDeleteContext)
  const queryCallback = useCallback((collection: Firebase.firestore.CollectionReference) => collection.orderBy('order').where('parent', '==', id || ''), [id])
  const [drawings, setDrawings, { loading, ...collection }] = useCollection<Project>(`site-data/${siteName}/drawings`, queryCallback)
  const [selectedDrawing, doc] = useDoc<Project>(`site-data/${siteName}/drawings`, id || '')
  const showAddDialog = useMemo(() => location.pathname.indexOf('/drawings/add') >= 0 || Boolean(selectedDrawing && !selectedDrawing.isProject), [location, selectedDrawing])
  const dialogState = useMemo(() => {
    if (location.pathname.indexOf('/drawings/add') >= 0) {
      return { parent: selectedDrawing?.isProject ? id : '', title: '', isProject: false, description: '' }
    } else {
      return { id, parent: selectedDrawing?.parent || '', title: selectedDrawing?.title || '', isProject: selectedDrawing?.isProject || false, description: selectedDrawing?.description || '' }
    }
  }, [selectedDrawing, id, location])
  const firestore = useFirestore()

  const handleSave = useCallback(async ({ files, ...project }: Project & { id?: string, files?: Array<{ title: string, url: string, thumbUrl: string }> }) => {
    if (project.id) {
      await doc.update(project)
    } else if (files) {
      project.url = files[0].url
      project.thumbUrl = files[0].thumbUrl
      const docRef = await collection.add({ ...project, order: 0 })
      if (project.isProject) {
        files.forEach(async f => {
          const data = {
            title: f.title,
            parent: docRef.id,
            isProject: false,
            url: f.url,
            thumbUrl: f.thumbUrl,
            order: 0
          }
          await collection.add(data, true)
        })
      }
    }
    history.push(selectedDrawing?.parent ? `/edit/page/${page}/edit/${selectedDrawing.parent}` : selectedDrawing?.isProject ? `/edit/page/${page}/edit/${id}` : `/edit/page/${page}`)
  }, [history, collection, doc, page, id, selectedDrawing])

  const handleSaveProject = useCallback((project: Partial<Project>) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      doc.update(project)
    }, 500)
  }, [doc])

  const handleToggleProjectCover = useCallback((thumbUrl: string) => {
    doc.update({ thumbUrl })
  }, [doc])

  const handleDelete = useCallback((id: string) => {
    confirmDelete.open({
      deleteText: 'Weet je zeker dat je dit project/deze tekening wilt verwijderen?',
      onConfirm: async () => {
        await collection.delete(id)
      }
    })
  }, [confirmDelete, collection])

  const handleSortEnd = useCallback(({ oldIndex, newIndex }) => {
    const sorted = arrayMove(drawings, oldIndex, newIndex)
    setDrawings(sorted)
    const batch = firestore.batch()
    sorted.forEach((drawing, k) => {
      collection.update(drawing.id, { order: k }, { batch })
    })
    batch.commit()
  }, [setDrawings, collection, firestore, drawings])

  return <Box display="flex">
    <Drawer classes={{ paper: drawerPaper }} variant="permanent" anchor="left">
      <SortableGrid
        drawings={drawings}
        selectedDrawing={selectedDrawing || undefined}
        subheader={selectedDrawing?.isProject ? 'Tekeningen' : 'Tekeningen en projecten'}
        axis="y"
        useDragHandle={true}
        lockAxis="y"
        lockToContainerEdges={true}
        onSortEnd={handleSortEnd}
        onDelete={handleDelete}
        onToggleCover={handleToggleProjectCover}
      />
    </Drawer>
    <Box flex={1} maxHeight={500} overflow="auto" padding={0.5} >
      <Masonry style={{ width: '100%' }} options={{ stamp: '.stamp', itemSelector: '.grid-item', columnWidth: '.grid-sizer' }}>
        <Box className="grid-sizer" />
        {selectedDrawing?.isProject ? <Box className="stamp" top={0} left={0} position="absolute" padding={0.5}><div style={{ background: '#eee', padding: 16 }}>
          <TextField label="Project titel" margin="normal" variant="filled" defaultValue={selectedDrawing.title} onChange={e => handleSaveProject({ title: e.target.value })} />
          <RichTextEditor value={selectedDrawing.description || ''} onChange={(e, description) => handleSaveProject({ description })} />
        </div></Box> :
          <Box className="stamp" top={0} right={0} position="absolute" padding={0.5}><div style={{ background: '#eee', padding: 16 }}>
            <RichTextEditor value={props?.content || ''} onChange={(e, content) => onChange({ ...props, content })} />
          </div></Box>}
        {drawings.map((drawing, k) => <Box key={k} className="grid-item" padding={0.5} paddingBottom={0}><img src={drawing.thumbUrl} alt="" style={{ width: '100%' }} /></Box>)}
      </Masonry>
    </Box>
    <AddDrawingDialog open={showAddDialog} onClose={() => history.push(`/edit/page/${page}${selectedDrawing?.parent ? `/edit/${selectedDrawing?.parent}` : selectedDrawing?.isProject ? `/edit/${id}` : ''}`)} onSave={handleSave} initialState={dialogState} />
  </Box>
}

const definition: BlockDefinition = {
  icon: 'dashboard',
  name: 'drawings',
  title: 'Drawings masonry',
  content: [
    {
      name: 'drawings',
      properties: [],
      title: 'Tekeningen & projecten',
      icon: 'image'
    },
    {
      name: 'news',
      properties: [],
      title: 'Nieuws',
      icon: 'public'
    }
  ],
  Main,
  Edit
}

export default definition
