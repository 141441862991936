import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Icon, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, makeStyles, Slide, Switch, TextField, Typography } from '@material-ui/core'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { BlockDefinition, BlockProps } from '.'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { TransitionProps } from '@material-ui/core/transitions/transition'
import { siteName } from '../..'
import { useCollection } from '../../hooks/firebase'
import { ConfirmDeleteContext } from '../../components/ConfirmDeleteDialog'
import { DatePicker } from '@material-ui/pickers'
import Firebase from 'firebase/app'
import { Timeline, TimelineContent, TimelineItem, TimelineOppositeContent } from '@material-ui/lab'
import RichTextEditor from '../../components/RichTextEditor'

type Exhibition = {
  isProject: boolean
  date: number
  name: string
  description: string
  order?: number
}

type ExhibitionBlockProps = BlockProps<{
  collectionName?: string
  subHeading?: string
}>

const useStyles = makeStyles({
  rte: {
    '& p': {
      margin: 0
    }
  }
})

function Main({ props }: { props?: ExhibitionBlockProps }) {
  const { rte } = useStyles()
  const queryCallback = useCallback((collection: Firebase.firestore.CollectionReference) => collection.orderBy('date', 'desc'), [])
  const [exhibitions] = useCollection<Exhibition>(`site-data/${siteName}/${props?.collectionName}`, queryCallback)
  const perYear = useMemo(() => exhibitions.reduce((perYear, exhibition) => {
    const index = perYear.findIndex(({ year }) => year === new Date(exhibition.date).getFullYear())
    if (index >= 0) {
      perYear[index].exhibitions.push(exhibition)
    } else {
      perYear.push({ year: new Date(exhibition.date).getFullYear(), exhibitions: [exhibition] })
    }
    return perYear
  }, [] as Array<{ year: number, exhibitions: Array<Exhibition & { id: string }> }>), [exhibitions])
  return <Box>
    {props?.subHeading ? <Typography variant="h5">{props?.subHeading}</Typography> : null}
    <Timeline align="left">
      {perYear.map(({ year, exhibitions }) => <TimelineItem key={year}>
        <TimelineOppositeContent style={{ flex: 'none' }}><Typography variant="body2" color="textSecondary">{year}</Typography></TimelineOppositeContent>
        <TimelineContent>
          <List disablePadding={true} style={{ marginTop: -16 }}>
            {exhibitions.map((exhibition) => <ListItem key={exhibition.id}>
              <ListItemText
                primary={exhibition.name}
                secondary={
                  <Box dangerouslySetInnerHTML={{ __html: exhibition.description || '' }} className={rte} />
                }
              />
            </ListItem>)}
          </List>
        </TimelineContent>
      </TimelineItem>)}
    </Timeline>
  </Box>
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

function AddExhibitionDialog({ open, initialState, onClose, onSave }: React.PropsWithChildren<{ open: boolean, initialState: Exhibition & { id?: string }, onClose: () => void, onSave: (exhibition: Exhibition & { id?: string }) => Promise<void> }>) {
  const [state, setState] = useState(initialState)
  const [saving, setSaving] = useState(false)
  const disabled = useMemo(() => state.name === '' || state.date < 1, [state])
  const date = useMemo(() => new Date(state.date), [state.date])
  useEffect(() => {
    setState(initialState)
    setSaving(false)
  }, [open, initialState])
  const handleSave = useCallback(async () => {
    setSaving(true)
    onSave({ ...state })
  }, [state, onSave])
  return <Dialog open={open} onClose={onClose} TransitionComponent={Transition}>
    <DialogTitle>Tentoonstelling {state.id ? 'bewerken' : 'toevoegen'}</DialogTitle>
    <DialogContent>
      <FormControlLabel
        checked={state.isProject}
        onChange={() => setState(s => ({ ...s, isProject: !s.isProject }))}
        control={<Switch name="isProject" />}
        label="Dit is een project"
      />
      <DatePicker margin="normal" inputVariant="filled" label="Datum" name="date" required={true} fullWidth={true} value={date} onChange={d => setState(s => ({ ...s, date: d?.toDate().getTime() || 0 }))} />
      <TextField margin="normal" variant="filled" label="Naam" name="name" required={true} fullWidth={true} value={state.name} onChange={e => setState(s => ({ ...s, name: e.target.value }))} />
      <TextField
        margin="normal"
        multiline={true}
        variant="filled"
        label="Beschrijving"
        name="description"
        fullWidth={true}
        InputLabelProps={{ shrink: true }}
        InputProps={{ inputComponent: RtfInputComponent }}
        value={state.description}
        onChange={e => setState(s => ({ ...s, description: e.target.value }))}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={() => onClose()}>Annuleren</Button>
      <Button color="primary" onClick={handleSave} disabled={disabled}>Opslaan</Button>
    </DialogActions>
    {saving && <Box position="absolute" top={0} left={0} height="100%" width="100%" display="flex" alignItems="center" justifyContent="center" style={{ background: 'rgba(250, 250, 250, 0.87)' }}><CircularProgress size={120} color="primary" /></Box>}
  </Dialog>
}

function RtfInputComponent({ value, onChange }: React.PropsWithChildren<{ value?: string, onChange?: (e: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>) => void }>) {
  const handleChange = useCallback((e: { target: any }, value: string) => {
    onChange && onChange({ ...e, target: { ...e.target, value } } as React.FormEvent<HTMLTextAreaElement | HTMLInputElement>)
  }, [onChange])
  return <RichTextEditor value={value || ''} onChange={handleChange} />
}

function Edit({ props, onChange }: { props?: ExhibitionBlockProps, onChange: (props?: ExhibitionBlockProps) => void }) {
  const location = useLocation()
  const history = useHistory()
  const { page, id } = useParams<{ page: string, id?: string }>()
  const { rte } = useStyles()
  const queryCallback = useCallback((collection: Firebase.firestore.CollectionReference) => collection.orderBy('date', 'desc'), [])
  const [exhibitions, , { loading, ...collection }] = useCollection<Exhibition>(`site-data/${siteName}/${props?.collectionName}`, queryCallback)
  const perYear = useMemo(() => exhibitions.reduce((perYear, exhibition) => {
    const index = perYear.findIndex(({ year }) => year === new Date(exhibition.date).getFullYear())
    if (index >= 0) {
      perYear[index].exhibitions.push(exhibition)
    } else {
      perYear.push({ year: new Date(exhibition.date).getFullYear(), exhibitions: [exhibition] })
    }
    return perYear
  }, [] as Array<{ year: number, exhibitions: Array<Exhibition & { id: string }> }>), [exhibitions])
  const selected = useMemo(() => id && exhibitions.length > 0 ? exhibitions.find(e => e.id === id) : undefined, [id, exhibitions])
  const showAddDialog = useMemo(() => location.pathname.indexOf(`/${page}/add`) >= 0 || Boolean(selected), [location, page, selected])
  const confirmDelete = useContext(ConfirmDeleteContext)
  const handleSave = useCallback(async (data: Exhibition & { id?: string }) => {
    if (!data.id) {
      await collection.add(data)
    } else {
      await collection.set(data.id, data, { merge: true })
    }
    history.push(`/edit/page/${page}`)
  }, [collection, history, page])
  const handleDelete = useCallback((id: string) => {
    confirmDelete.open({
      deleteText: 'Weet je zeker dat je deze tentoonstelling wilt verwijderen?',
      onConfirm: async () => {
        await collection.delete(id)
      }
    })
  }, [confirmDelete, collection])
  return <Box height="100%" overflow="auto">
    <Box padding={2}>
      <TextField label="Titel" variant="filled" value={props?.subHeading} onChange={e => onChange({ ...props, subHeading: e.target.value })} />
      <TextField label="Collectie naam" variant="filled" value={props?.collectionName} onChange={e => onChange({ ...props, collectionName: e.target.value })} />
      <Button variant="contained" color="primary" onClick={() => history.push(`/edit/page/${page}/add`)}>Nieuwe toevoegen</Button>
    </Box>
    <Timeline align="left">
      {perYear.map(({ year, exhibitions }, k) => <TimelineItem key={k}>
        <TimelineOppositeContent style={{ flex: 'none' }}><Typography variant="body2" color="textSecondary">{year}</Typography></TimelineOppositeContent>
        <TimelineContent>
          <List disablePadding={true} style={{ marginTop: -16 }}>
            {exhibitions.sort((a, b) => a.date > b.date ? -1 : b.date > a.date ? 1 : 0).map((exhibition, n) => <ListItem key={k}>
              <ListItemText
                primary={exhibition.name}
                secondary={
                  <Box dangerouslySetInnerHTML={{ __html: exhibition.description || '' }} className={rte} />
                }
              />
              <ListItemSecondaryAction>
                <IconButton onClick={() => history.push(`/edit/page/${page}/edit/${exhibition.id}`)}><Icon color="primary">create</Icon></IconButton>
                <IconButton onClick={() => handleDelete(exhibition.id)}><Icon color="error">delete</Icon></IconButton>
              </ListItemSecondaryAction>
            </ListItem>)}
          </List>
        </TimelineContent>
      </TimelineItem>)}
    </Timeline>
    <AddExhibitionDialog open={showAddDialog} onClose={() => history.push(`/edit/page/${page}`)} onSave={handleSave} initialState={selected || { isProject: false, date: new Date().getTime(), name: '', description: '' }} />
  </Box>
}

const definition: BlockDefinition = {
  icon: 'account_balance',
  name: 'exhibitions',
  title: 'Tentoonstellingen',
  content: '',
  Main,
  Edit
}

export default definition
